import { Injectable } from '@angular/core';
import * as Msal from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { AuthService } from '../api/common/auth.service';
import { ExternalDomainConfig, SessionApiService } from '../api/session-api.service';
import { NotificationService } from '../pages/notification.service';
import { SpinnerService } from '../pages/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftService {
  private msalInstance: Msal.PublicClientApplication | null = null;
  private config: ExternalDomainConfig | undefined;
  private clientId: string | undefined;
  private scopes: string[] = [];

  public constructor(
    private authService: AuthService,
    private sessionApiService: SessionApiService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
  ) {}

  public init(config: ExternalDomainConfig, username: string): void {
    this.config = config;
    this.clientId = config.clientId;
    this.scopes = config.scopes.split(';');

    if (this.msalInstance) {
      console.warn('Msal already initialized');
    } else {
      this.msalInstance = new Msal.PublicClientApplication(MicrosoftService.getConfiguration(this.clientId));
      this.msalInstance.handleRedirectPromise().then(
        response => {
          this.redirectHandler(response, username);
        },
        error => {
          console.error(error);
          if (error instanceof Msal.InteractionRequiredAuthError && error.errorCode === 'consent_required') {
            this.authService.logOut(true);
          } else {
            this.notificationService.error('bkwa.errors.generic.oneLine', true);
          }
          this.spinnerService.hide();
        },
      );
    }
  }

  private redirectLogin(email: string): void {
    if (!this.msalInstance) {
      throw new Error('Instance not initialized');
    }
    this.msalInstance.loginRedirect({ scopes: this.scopes, loginHint: email });
  }

  public logOut(email: string): Promise<void> {
    if (!this.msalInstance) {
      throw new Error('Instance not initialized');
    }
    this.spinnerService.show();
    return this.msalInstance.logoutRedirect({
      logoutHint: email,
    });
  }

  private redirectHandler(resp: Msal.AuthenticationResult | null, username: string): void {
    if (resp != null) {
      this.accessTokenHandler(resp);
    } else {
      const currentRoute = window.location.hash;
      if (currentRoute) {
        this.authService.saveRedirectAfterLogin(currentRoute.substring(1));
      }
      this.spinnerService.show(15000);
      this.redirectLogin(username);
    }
  }

  private accessTokenHandler(authentication: Msal.AuthenticationResult): void {
    if (authentication.accessToken === null) {
      throw new Error('Access token is null');
    }
    this.sessionApiService.loginExternal(authentication.accessToken, 'MICROSOFT').then(() => {
      if (!this.config) {
        throw new Error('Microsoft config is undefined');
      }
      this.authService.logInExternal(
        'MICROSOFT',
        this.config,
        authentication.account?.username || '',
        authentication.accessToken,
        authentication.expiresOn,
      );
      window.setTimeout(() => {
        this.spinnerService.hide();
      }, 300);
    });
  }

  private static getConfiguration(clientId: string): Msal.Configuration {
    const redirectUri = window.location.origin + '/';
    return {
      auth: {
        clientId,
        redirectUri,
        postLogoutRedirectUri: redirectUri,
      },
      // eslint-disable-next-line multiline-ternary
      cache: environment.production
        ? {
          cacheLocation: Msal.BrowserCacheLocation.MemoryStorage,
          storeAuthStateInCookie: true,
          secureCookies: window.location.protocol === 'https:',
        }
        : {
          // evitar guardar en las cookies, ya que hace saltar el firewall al realizar las peticiones
          cacheLocation: Msal.BrowserCacheLocation.SessionStorage,
        },
    };
  }
}
